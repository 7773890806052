@mixin overlap($transSide, $color, $location: top) {
  position: relative;
  background-color: $color;
  padding: 60px 15px 90px 15px;

  &::before {
    content: " ";
    background-image: linear-gradient(
      to $transSide $location,
      $color 0%,
      $color 50%,
      transparent 50%
    );
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 100%;
    left: 0;
  }
}
