@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@700&family=Roboto&display=swap");
@import "./styles/Banner.scss";
@import "./styles/Meet.scss";
@import "./styles/Skills.scss";
@import "./styles/Footer.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: #aaa;
}

html {
  font-size: 20px;
}

h1,
h2,
h3,
h4,
h4,
h5,
h6 {
  font-family: "Merriweather Sans", sans-serif;
  color: #eee;
}

p {
  line-height: 1.5;
}
