$hr-space: 20px;

@mixin title-and-tagline {
  &__title {
    &::after {
      content: " ";
      display: block;
      max-width: 900px;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #aaa;
      padding-top: $hr-space;
    }
  }

  &__tagline {
    padding-top: $hr-space;
  }
}
