@import "./mixins/TNT.scss";
@import "./mixins/Overlap.scss";

.skills {
  @include overlap(right, #3f4e85);

  &-inner {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;

    &--content,
    &--list {
      margin: 0 10px;
    }

    &--content {
      @include title-and-tagline;
      padding-bottom: 40px;
    }

    &--list {
      display: flex;
      flex-wrap: wrap;

      &__skill {
        flex-basis: 50%;
        padding-bottom: 20px;

        &:nth-child(odd) {
          padding-right: 20px;
        }

        &:nth-child(even) {
          padding-left: 20px;
        }

        .container {
          display: flex;
          padding-bottom: 10px;

          svg {
            fill: #333;
            max-width: 40px;
            width: 100%;
            height: 100%;
            margin-right: 10px;
            background-color: #eee;
            border-radius: 50%;
          }

          h3 {
            padding-bottom: 10px;
          }
        }

        &::after {
          content: " ";
          display: block;
          width: 100%;
          height: 2px;
          background-color: #aaa;
        }
      }

      @media (max-width: 875px) {
        flex-direction: column;

        &__skill {
          &:nth-child(odd) {
            padding-right: 0;
          }

          &:nth-child(even) {
            padding-left: 0;
          }
        }
      }
    }
  }
}
