@import "./mixins/TNT.scss";
@import "./mixins/Overlap.scss";

.meet {
  @include overlap(left, #45558d);

  &-inner {
    display: flex;
    max-width: 900px;
    width: 100%;
    text-align: right;
    margin: 0 auto;

    &--content,
    &--img-container {
      margin: 0 10px;
    }

    &--content {
      flex: 1;
      @include title-and-tagline;
    }

    &--img-container {
      &__img {
        // Make text wrap around image
        // https://css-tricks.com/almanac/properties/s/shape-outside/
        border-radius: 50%;
      }
    }

    @media (max-width: 640px) {
      flex-direction: column-reverse;

      &--img-container {
        max-width: 200px;
        margin: 0 auto 20px auto;

        &__img {
          width: 100%;
        }
      }
    }
  }
}
