@import "./mixins/TNT.scss";

.banner {
  position: relative;
  height: 90vh;
  background-image: linear-gradient(
      to top,
      rgba(46, 49, 65, 0.7),
      rgba(46, 49, 65, 0.7)
    ),
    url(../img/sunset.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom center;
  background-size: cover;

  &--inner {
    position: absolute;
    max-width: 900px;
    width: 100%;
    padding: 10px 15px;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include title-and-tagline;
  }
}
