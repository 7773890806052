@import "./mixins/TNT.scss";
@import "./mixins/Overlap.scss";

.footer {
  @include overlap(right, #3f4e85, bottom);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 60px;
  background-image: linear-gradient(
      to top,
      rgba(46, 49, 65, 0.7),
      rgba(46, 49, 65, 0.7)
    ),
    url(../img/stay-connected.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;

  &::before {
    top: 0%;
  }

  &-inner {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;

    &--content {
      @include title-and-tagline;
      padding-bottom: 40px;
    }

    &--connect {
      display: flex;

      &__links,
      &__form {
        flex: 1;
      }

      &__links {
        display: flex;
        flex-direction: column;

        .link {
          display: flex;
          margin: 10px 0;
          text-decoration: none;

          svg {
            width: 25px;
            height: auto;
            margin-right: 10px;
          }
        }
      }

      @media (max-width: 385px) {
        flex-direction: column;
      }
    }
  }

  &-reserved {
    bottom: 20px;
    font-size: 0.75rem;
    max-width: 900px;
    width: 100%;
    margin: 0 auto;

    ::before {
      content: " ";
      display: block;
      max-width: 900px;
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #aaa;
      padding-top: $hr-space;
    }
  }
}
